import { render, staticRenderFns } from "./index.vue?vue&type=template&id=0a9234f8&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./style.styl?vue&type=style&index=0&id=0a9234f8&prod&lang=stylus&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a9234f8",
  null
  
)

export default component.exports