<template>

    <div>

        <Navbar title="" :back="true" backurl="/#/package" />
        <div class="page">

            <div>
                <img src="@/assets/package/detail-h1.png" alt="">
            </div>

            <div class="content">
                
                <div class="row row-2 row-3">
                    <div class="row-center">
                        <div class="row-c ">
                            <div class="row-c-t">
                                <b>5</b>
                                <div>
                                    <div class="number">x2</div>
                                    <span>元</span>
                                </div>
                            </div>
                            <div class="ticket">立减券</div>
                        </div>
                        <div class="row-c ">
                            <div class="row-c-t">
                                <b>3</b>
                                <div>
                                    <span>元</span>
                                </div>
                            </div>
                            <div class="ticket">立减券</div>
                        </div>
                        <div class="row-c ">
                            <div class="row-c-t">
                                <b>2</b>
                                <div>
                                    <span>元</span>
                                </div>
                            </div>
                            <div class="ticket">立减券</div>
                        </div>
                    </div>

                </div>
                <div v-if="coupon.id == 9" class="row row-2 row-4">

                    <div class="row-center">

                        <div class="row-c ">
                            <div class="row-c-t">
                                <b>15</b>
                                <div>
                                    <div class="number">x3</div>
                                    <span>元</span>
                                </div>
                            </div>
                            <div class="ticket">立减券</div>

                        </div>
                        <div class="row-c ">
                            <div class="row-c-t">
                                <b>10</b>
                                <div>
                                    <div class="number">x2</div>
                                    <span>元</span>
                                </div>
                            </div>
                            <div class="ticket">立减券</div>
                        </div>
                    </div>
                </div>

                <div v-if="coupon.id == 10" class="row row-2 row-5">

                    <div class="row-center">
                        <div class="row-c ">
                            <div class="row-c-t">
                                <b>15</b>
                                <div>
                                    <div class="number">x3</div>
                                    <span>元</span>
                                </div>
                            </div>
                            <div class="ticket">立减券</div>

                        </div>
                        <div class="row-c ">
                            <div class="row-c-t">
                                <b>10</b>
                                <div>
                                    <div class="number">x2</div>
                                    <span>元</span>
                                </div>
                            </div>
                            <div class="ticket">立减券</div>
                        </div>
                    </div>
                </div>

                <div class="buy" @click="buyNow">
                    <img src="@/assets/package/buy.png" alt="">
                </div>

                <div class="rule">
                    <div v-show="coupon.parking_voucher_rules" class="rule-item ">
                        <div class="rule-tip">
                            停车券使用规则
                        </div>
                        <div class="rule-text rule-text-top">
                            <!-- <p>1. 该券为捷停车无门槛停车立减券；</p>
                            <p>2. 领取成功后，用领取手机号登录“捷停车”APP、微信公众号或小程序，个人中心-优惠券查看有效期及详细说明；</p>
                            <p>3. 该停车券仅限在捷停车运营的停车场临停缴费时抵扣，用户如在线下缴纳现金时该券不可使用；</p>
                            <p>4. 可适用车场以捷停车线下车场实际情况为准，具体支持车场清单可在［优惠券］页面—对应券［查看详情］中查看；</p>
                            <p>5. 捷停车客服热线：400-700-5305；</p> -->

                            <div style="white-space: pre-wrap;" v-html="coupon.parking_voucher_rules"></div>
                        </div>
                    </div>
                    <div v-show="coupon.parking_space_rules" class="rule-item">
                        <div class="rule-tip">
                            车位券使用规则
                        </div>
                        <div class="rule-text">

                            <p>1. <span class="rule-link"><img src="@/assets/package/hand-l.png" alt="">
                                <a target="_blank" href="https://www.jslife.com.cn/jtc-h5/coupon/coupon-list.html?forceAppType=WX_JTC#ApplyParking/CPPPKYbOwTaoE8FtQ9pL">
                                    查看可适用车场
                                </a>
                                <img src="@/assets/package/hand-r.png" alt=""></span></p>
                            <!-- <p>2. 领取成功后，用领取手机号登录“捷停车”APP、微信公众号或小程序，个人中心-优惠券查看有效期及详细说明；</p>
                            <p>3. 该停车券仅限在捷停车运营的停车场临停缴费时抵扣，用户如在线下缴纳现金时该券不可使用；</p>
                            <p>4. 可适用车场以捷停车线下车场实际情况为准，具体支持车场清单可在［优惠券］页面—对应券［查看详情］中查看；</p>
                            <p>5. 捷停车客服热线：400-700-5305；</p> -->
                            <div style="white-space: pre-wrap;" v-html="coupon.parking_space_rules"></div>
                        </div>
                    </div>
                    <div v-show="coupon.charging_voucher_rules" class="rule-item">
                        <div class="rule-tip">
                            充电券使用规则
                        </div>
                        <div class="rule-text">

                            <p>1. <span class="rule-link"><img src="@/assets/package/hand-l.png" alt="">
                                <a target="_blank" href="https://www.jslife.com.cn/jtc-h5/coupon/coupon-list.html#ApplyStations/CPPMpTgW6KTVqbkVQyBw">
                                    查看可适用车场
                                </a>
                                    <img src="@/assets/package/hand-r.png" alt=""></span></p>
                            <!-- <p>2. 领取成功后，用领取手机号登录“捷停车”APP、微信公众号或小程序，个人中心-优惠券查看有效期及详细说明；</p>
                            <p>3. 该停车券仅限在捷停车运营的停车场临停缴费时抵扣，用户如在线下缴纳现金时该券不可使用；</p>
                            <p>4. 可适用车场以捷停车线下车场实际情况为准，具体支持车场清单可在［优惠券］页面—对应券［查看详情］中查看；</p>
                            <p>5. 捷停车客服热线：400-700-5305；</p> -->
                            <div style="white-space: pre-wrap;" v-html="coupon.charging_voucher_rules"></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="adtip">广告</div>
        </div>
    </div>


</template>

<script>
import Navbar from '../../components/navbar.vue';
import * as api from '../../http-service/api-client';

export default {
    name: 'PackageDetail',
    components: {
        Navbar
    },
    data(){
        return {
            placeChannel: 'IBCS', // 手机银行
            type: 1,
            coupon: {},
            isSubmit: true,
        }
    },
    methods: {
        packageDetail: function(){
            api.couponsPackageDetail(this.$route.query.id).then(result => {
                if (result.data.code == 200) {
                    this.coupon = result.data.data
                    // console.log(this.coupon)
                }
            })
        },
        buyNow: function(){
            if (this.isSubmit === false) {
                console.log('不可重复提交')
                return false
            }
            this.isSubmit = false

            let str = '您选购的权益将发放至手机尾号为 xxxx 的“捷停车”账号，支付成功后，您可使用该手机号登录“捷停车”小程序使用或查看您的权益。'
            let userInfo = sessionStorage.getItem('userInfo')
            let mobile = ''
            let lastFour = ''
            if (userInfo) {
                userInfo = JSON.parse(userInfo)
                mobile = userInfo.mobile
                lastFour = mobile.slice(-4)
                str = str.split('xxxx').join(lastFour);
            }
            
            alert(str)

            var args = {
                packageId: this.$route.query.id,
                placeChannel: this.placeChannel
            }
            let result = {}
            api.createOrder(args).then(res => {
                result = res.data
                console.log(result)
                if (result.code != 200) {
                    alert(result.msg)
                    return false;
                }  
                this.cgbPay(result)
            })
            
        },
        cgbPay: function(result){
            let data = {
                keyCiphertext: result.data.keyCiphertext,
                textToDecrypt: result.data.textToDecrypt,
                signature: result.data.signature,
                appId: result.data.appId,
                businessType: "06",
                // isCallback: false
            }
            AlipayJSBridge.call('cgbPay', data,
                (res) => {
                    this.isSubmit = true
                    if (res.respCode == '0000') {
                        alert(res.respMsg)
                    } else {
                        var msg = res.errorMsg ? res.errorMsg : res.respMsg
                        alert(msg)
                    }
                }
            )
        }
    },
    created(){
        this.packageDetail()
    }

}

</script>
<style lang="stylus" scoped>

.page {
    margin-top 70px
    background linear-gradient(#ffddc0, #fff0e2)
    overflow hidden
}

.content {
 
  border-radius: 40px;
  background-color: rgb(255, 255, 255);
    margin 26px
    margin-top 0
    padding 36px 10px
    padding-bottom 0
}

.buy {
    margin auto
    margin-top 48px
    text-align center
    img {
        width 478px
    }
}

.rule {
    padding 18px
}
.rule-item  {
    padding-bottom 20px
}
.rule-tip {
    width 216px
    height 35px
    background url(@/assets/package/rule.png) center / contain no-repeat
    font-weight bold
    font-size 24px
    color #e54b18
    text-align center
    line-height 35px
    
}

.rule-text {
    color: #4c4948;
    font-size 24px;
    line-height 42px;
    padding 14px
    img {
        height 28px
    }
}

.rule-link {
    
    color: #e54b18;
    text-decoration underline    
    a {
        color: #e54b18;
    }
}
 

</style>

<style lang="stylus" scoped src="./style.styl"></style>