var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"page"},[_vm._m(0),_c('div',{staticClass:"content"},[_vm._m(1),(_vm.coupon.id == 9)?_c('div',{staticClass:"row row-2 row-4"},[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.coupon.id == 10)?_c('div',{staticClass:"row row-2 row-5"},[_vm._m(4)]):_vm._e(),_c('div',{staticClass:"buy",on:{"click":_vm.buyNow}},[_c('img',{attrs:{"src":require("@/assets/package/buy.png"),"alt":""}})]),_c('div',{staticClass:"rule"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.coupon.parking_voucher_rules),expression:"coupon.parking_voucher_rules"}],staticClass:"rule-item"},[_c('div',{staticClass:"rule-tip"},[_vm._v(" 停车券使用规则 ")]),_c('div',{staticClass:"rule-text rule-text-top"},[_c('div',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.coupon.parking_voucher_rules)}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.coupon.parking_space_rules),expression:"coupon.parking_space_rules"}],staticClass:"rule-item"},[_c('div',{staticClass:"rule-tip"},[_vm._v(" 车位券使用规则 ")]),_c('div',{staticClass:"rule-text"},[_vm._m(5),_c('div',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.coupon.parking_space_rules)}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.coupon.charging_voucher_rules),expression:"coupon.charging_voucher_rules"}],staticClass:"rule-item"},[_c('div',{staticClass:"rule-tip"},[_vm._v(" 充电券使用规则 ")]),_c('div',{staticClass:"rule-text"},[_vm._m(6),_c('div',{staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.coupon.charging_voucher_rules)}})])])])]),_c('div',{staticClass:"adtip"},[_vm._v("广告")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("@/assets/package/detail-h1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-2 row-3"},[_c('div',{staticClass:"row-center"},[_c('div',{staticClass:"row-c"},[_c('div',{staticClass:"row-c-t"},[_c('b',[_vm._v("5")]),_c('div',[_c('div',{staticClass:"number"},[_vm._v("x2")]),_c('span',[_vm._v("元")])])]),_c('div',{staticClass:"ticket"},[_vm._v("立减券")])]),_c('div',{staticClass:"row-c"},[_c('div',{staticClass:"row-c-t"},[_c('b',[_vm._v("3")]),_c('div',[_c('span',[_vm._v("元")])])]),_c('div',{staticClass:"ticket"},[_vm._v("立减券")])]),_c('div',{staticClass:"row-c"},[_c('div',{staticClass:"row-c-t"},[_c('b',[_vm._v("2")]),_c('div',[_c('span',[_vm._v("元")])])]),_c('div',{staticClass:"ticket"},[_vm._v("立减券")])])]),_c('div',{staticClass:"row-center"},[_c('b',{staticStyle:{"margin-top":"28px"}},[_vm._v("停车立减券包")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-center"},[_c('div',{staticClass:"row-c"},[_c('div',{staticClass:"row-c-t"},[_c('b',[_vm._v("15")]),_c('div',[_c('div',{staticClass:"number"},[_vm._v("x3")]),_c('span',[_vm._v("元")])])]),_c('div',{staticClass:"ticket"},[_vm._v("立减券")])]),_c('div',{staticClass:"row-c"},[_c('div',{staticClass:"row-c-t"},[_c('b',[_vm._v("10")]),_c('div',[_c('div',{staticClass:"number"},[_vm._v("x2")]),_c('span',[_vm._v("元")])])]),_c('div',{staticClass:"ticket"},[_vm._v("立减券")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-center"},[_c('b',{staticStyle:{"margin-top":"28px"}},[_vm._v("车位优选券包")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-center"},[_c('div',{staticClass:"row-c"},[_c('div',{staticClass:"row-c-t"},[_c('b',[_vm._v("15")]),_c('div',[_c('div',{staticClass:"number"},[_vm._v("x3")]),_c('span',[_vm._v("元")])])]),_c('div',{staticClass:"ticket"},[_vm._v("立减券")])]),_c('div',{staticClass:"row-c"},[_c('div',{staticClass:"row-c-t"},[_c('b',[_vm._v("10")]),_c('div',[_c('div',{staticClass:"number"},[_vm._v("x2")]),_c('span',[_vm._v("元")])])]),_c('div',{staticClass:"ticket"},[_vm._v("立减券")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("1. "),_c('span',{staticClass:"rule-link"},[_c('img',{attrs:{"src":require("@/assets/package/hand-l.png"),"alt":""}}),_c('a',{attrs:{"target":"_blank","href":"https://www.jslife.com.cn/jtc-uni-h5/index.html#/pages/search-stall/search-stall"}},[_vm._v(" 查看可适用车场 ")]),_c('img',{attrs:{"src":require("@/assets/package/hand-r.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("1. "),_c('span',{staticClass:"rule-link"},[_c('img',{attrs:{"src":require("@/assets/package/hand-l.png"),"alt":""}}),_c('a',{attrs:{"target":"_blank","href":"https://www.jslife.com.cn/jtc-h5/coupon/coupon-list.html#ApplyStations/CPPMpTgW6KTVqbkVQyBw"}},[_vm._v(" 查看可适用电站 ")]),_c('img',{attrs:{"src":require("@/assets/package/hand-r.png"),"alt":""}})])])
}]

export { render, staticRenderFns }